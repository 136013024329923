/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ General Site ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Backgrounds */
.header-bg {background: $color-header-bg}
.main-bin {background: $color-bodybg}

/* Typography */
html, body {margin: 0; padding: 0; line-height: $default-lineheight; font-size: $default-fontsize; font-weight: $default-fontweight; color: $default-fontcolor}
html, body {font-family: $default-fontstack; background: $color-footer-bg}

small {font-size:11px;}

/* Text Headers */
h1, h2, h3, h4 {font-family: $header-fontstack;}
h1, h2 {font-size: 24px;}
#page-content h2 {font-size: 20px; font-weight: 400; font-style: italic}
#page-content h3 {font-size: 18px; font-weight: 600; color: #000}
#page-content h4 {font-size: 18px;}
#page-content .list-item h2 {margin-bottom: 0;}
#page-content .list-item h3 {margin-bottom: 0;}
#page-content .list-item h4 {margin: 0}

#page-aside h2 {color: $header-fontcolor; font-size: 23px; line-height: 88px; border-bottom: 2px solid #e3e3e3}
#page-title h1 {font-family: 'Times New Roman', 'Times', serif; color: $header-fontcolor; font-size: 40px; line-height: 44px; }
#page-title h1.news-title {font-size: 36px;}

/* links */
a {color: $color-link}
.uk-active a,
a:active {text-decoration: none; color: $color-active;}
a:hover {text-decoration: none; color: $color-hover;}

/* Colour stuff */
.color-primary {color: $color-primary !important;}
.color-secondary {color: $color-secondary !important;}
.color-tertiary {color: $color-tertiary !important;}
.bg-primary {background-color: $color-primary !important;}
.bg-secondary {background-color: $color-secondary !important;}
.bg-tertiary {background-color: $color-tertiary !important;}
.bg-white {background-color: $color-white !important;}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Navigation ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Sticky nav */
.navbar.sticky-nav {position: fixed; left: 0; top: 0; display: block; z-index: 2;}
.header-bg.sticky-nav {margin-top: $navbar-height;}

/* Off-canvas nav */
.mobile-nav {position: absolute; width: inherit; height: 30px; right: 20px; top: 50px;}
.mobile-nav a {color: $color-primary;}
.uk-offcanvas-bar {background: #dddddd;}
.uk-nav-offcanvas > li > a {color: #333}
.uk-nav-offcanvas>.uk-open>a, html:not(.uk-touch) .uk-nav-offcanvas>li>a:focus, html:not(.uk-touch) .uk-nav-offcanvas>li>a:hover {background: darken($color-navbar-bg,15%); color: $color-primary}
.uk-nav-offcanvas ul a span {font-family: $nav-fontstack; color: rgba(0,0,0,0.6)}
.uk-nav-offcanvas ul a span:hover {color: rgba(0,0,0,1)}
.uk-nav-header .uk-panel {margin: 0}
.burgers {position: absolute; left: 0; top: 0; text-align: left; font-size: 30px}
.uk-navbar-toggle {float: none; font-size: 22px}
.uk-nav-offcanvas>li {font-size: 16px}
.uk-offcanvas {}
.uk-offcanvas li.uk-nav-divider {border-top: 1px solid #222; border-bottom: 1px solid #555; border-width: 1px 0}
.uk-offcanvas .legal {color: #333; font-size: 12px}

/* Main nav */
.navbar {font-family: $nav-fontstack; height: $navbar-height; position: relative; width: 100%;}
.navbar-bg {background-color: $color-navbar-bg;}

/* Navbar Search box */
.navbar .uk-search-field {border: 1px solid #2a2a2a; background-color: #5d5d5d; @include border-radius(4px); width: 130px; height: 35px; margin-top: 5px; padding: 0 30px 0 6px; font-size: 13px;}
.navbar .uk-search-field:focus {background-color: rgba($color-white,0.9); width: 200px;}
.navbar .uk-search:before {left: auto; right: 0; top: 6px;}

/* Main navigation */

/* Default settings */
.main-nav, .main-nav * {list-style: none; margin: 0; padding: 0;}
.main-nav li {position: relative;}
.main-nav ul {position: absolute; display: none; top: 100%; left: 0; z-index: 99;}
.main-nav > li {float: left;}
.main-nav li:hover > ul, .main-nav li.hover > ul {display: block;}
.main-nav a {display: block; position: relative;}
.main-nav > ul {top: 0; left: 100%; transition: all .2s ease-in-out;}

/* Customized based on theme */
.main-nav > li {height: 42px; margin: 0 1px; padding: 0;}
.main-nav > li > a {display: block; color: $nav-color; margin: 0; padding: 0 10px; line-height: $navbar-height; font-size: $nav-fontsize; font-weight: 400; text-transform: uppercase }
.main-nav > li:hover, .main-nav > li > a:hover, .main-nav > li:hover > a, .main-nav > li > a:focus {color: $color-white; background: $color-primary}
.main-nav > li.uk-active > a {color: $color-white; background: $color-primary}
.main-nav li.last {padding-right: 0}

.main-nav > li > a {border-bottom: 2px solid #ffffff}
.main-nav > li.uk-parent:hover > a {border-bottom: 2px solid $color-primary}

/* Flip dropdown to avoid browser boundary */
.main-nav li.last.uk-active,
.main-nav li.last ul {right: 0; left: auto;}

/* Secondary level */
.main-nav ul {margin-left: 1px; font-family: $default-fontstack; min-width: 200px; background: $color-dropdown; text-align: left; padding: 8px; left: -1px;}
.main-nav ul li, .main-nav ul li a {color: rgba($color-white,0.5); font-size: 14px;}
.main-nav ul > li.uk-parent > a {display: block}
.main-nav li:hover ul, .main-nav > li a:hover ul, .main-nav > li:hover a ul {display: block;color: rgba($color-white,1);}

/* Children */
.main-nav li li > a {color: $color-tertiary; padding: 5px 20px;font-weight: normal; text-transform: none}
.main-nav li li > a:hover, .main-nav li li:hover > a, .main-nav li li.uk-active > a {color: $color-white;}

/* Tertiary level */
.main-nav ul ul {display: block; position: relative; left: 0; background: none; padding: 0 20px;}

/* Side Nav */
#page-aside #subnav > ul {padding-bottom: 40px;}
#page-aside #subnav ul.uk-list ul {padding-left: 0}
#page-aside #subnav ul.uk-list ul li {padding-left: 20px}
#page-aside #subnav ul li {padding-top: 5px;}
#page-aside #subnav ul li a {display: block; font-size: 15px; line-height: 25px;}
#page-aside #subnav ul li a:hover,
#page-aside #subnav ul li.uk-active a {color: $color-white;background: $color-primary;}
#page-aside #subnav ul li span {width: 18px; display: inline-block; -webkit-transition: width 1s, padding 1s; /* Safari */
    transition: width 1s, padding 1s;}
#page-aside #subnav ul li a:hover span,
#page-aside #subnav ul li.uk-active a span {width: 28px; padding-left: 10px;}
#page-aside #subnav ul li ul li a {-webkit-transition: width 1s, padding 1s; /* Safari */
    transition: width 1s, padding 1s;}
#page-aside #subnav ul li ul li a:hover {padding-left: 10px;}

/* Footer nav */
.footer-nav, .footer-nav * {list-style: none; margin: 0; padding: 0;}
.footer-nav li {padding-right: 20px; float: left}
.footer-nav li a {color: #4f4f4f; font-size: 12px; font-weight: 400; font-family: 'Georgia', 'Times New Roman', 'Times', serif; text-transform: uppercase}
.footer-nav li a:hover {color: $color-primary}


/* Mobile subnav */
#subnav-selector {margin-top: 2px !important}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Sliders and Headers ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Sliders */
#slider {width: 100%; height: $sub-slider-height; position: relative; z-index: 1; top: 0; overflow: hidden;}
.home .slide {height: $home-slider-height} 
.home #slider {height: $home-slider-height}

ul#slider-nav  {width: auto; margin-top: -36px; left: 50%; list-style-type: none; position: absolute; z-index: 105}
ul#slider-nav li {padding: 5px; float: left}
ul#slider-nav li a {display: block; width: 15px; height: 10px; color: $color-primary; font-size: 16px; background: $color-white; border: 2px solid $color-white;}
ul#slider-nav li.cycle-pager-active a {background: $color-primary}

/* Tagline */
.tagline {position: absolute; bottom: 30px;}
.tagline h2 {padding: 0; margin: 0; font-family: $tagline-fontstack; font-weight: $tagline-fontweight; color: $color-white; font-size: $tagline-fontsize; line-height: $tagline-lineheight;}

/* Logo */
.logo {display: block;}

/* Stock Quote */
.stock-symbol {color:#ffffff; font-size: 18px; font-weight: 700; line-height: 56px; background: $color-primary}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Page Structure ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#page-body {padding-top: 15px; position: relative; background-color: $color-white}
.home #page-body { padding-top: 0; position: relative; background-color: $color-white}
#page-content span[style] {line-height: auto}
#page-title {height: 90px; border-bottom: 2px solid #e3e3e3;}

/* List */
.item-list {line-height: normal;}
.item-list a {text-decoration: underline;}

/* Footer */
#page-footer {min-height: $footer-height; line-height: 16px; color: $color-footer-text; font-size: $footer-fontsize; background: $color-footer-bg}
.footer-address strong {color: $color-primary;}
.legal a {color: #5a5a5a; font-size: 13px;}
.adnet a {font-size: 12px; color: rgba(#5a5a5a,0.5);}
.adnet a:hover {color: rgba(#5a5a5a,1);}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Home Page ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Home Page Text */
.home-text a.more-link {display: inline-block; padding: 0 30px; color: #fff; font-size: 16px; line-height: 50px; font-weight: 400; background: rgba($color-primary,1);}
.home-text a.more-link:hover {background: rgba($color-primary,0.43);}
.home-text h1 {font-size: 40px;}
.home-subtext {padding-top: 25px; border-top: 1px dotted #acacac}
.home-subtext ul {list-style-type: none}
.home-subtext ul li {padding-bottom: 10px}
.home-subtext ul li::before {content: "> "; color: $color-primary}
#page-content .home-subtext h2 {font-size: 23px; font-style: normal}

/* Home Page Message Box */
.home-message {width: 100%; background: #f1f1f1; font-size: 11px; line-height: 14px;}
a.message-link {display: inline-block; padding: 0 20px; font-size: 15px; line-height: 18px; border-left: 1px solid $color-tertiary}
a.message-link.first {border-left: none}

/* Feature boxes - Home Page */
.feature {background: $color-footer-bg;}
.feature-content {padding: 25px; font-size: 13px; line-height: 18px;}
#page-content .feature-content h3 {color: $color-primary}
.feature-content a:hover {color: #000000}
a.feature-link {display: block; width: 100%; min-height: 70px; color: #fff; font-size: 30px; line-height: 70px; font-weight: 400; text-align: center; background: rgba(0,0,0,0.34); position: absolute; bottom: 0; left: 0;}
a.feature-link:hover {color: #a0a0a0; background: rgba(0,0,0,1);}

/* News Item list - Home Page */
#page-content .latest-news h2 {font-size: 40px; font-style: normal}
.news-item {border-left: 1px solid #dbdbdb}
.news-item a {color: #000000; font-size: 15px;}
#page-content .news-item a h3 {color: $color-primary; font-weight: 400}
.news-item a:hover {color: $color-primary}
.news-item.first {border-left: none}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Sub-Pages ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Managment Page */
.bio-list .list-item {padding-bottom: 25px; border-bottom: 1px solid $color-tertiary}
.bio-list .last .list-item {padding-bottom: 25px; border-bottom: none}

/* Committees Page */
.committees .list-item h3 {padding-top: 25px; border-top: 1px solid $color-tertiary}

/* Corporate Policy Page */
.policies-list .list-item {font-size: 18px; border-bottom: 1px solid $color-tertiary}
.policies-list .list-item i {line-height: 26px;}

/* Photo Gallery Page */
.gallery-text .uk-overlay-area-content,
.gallery-text {font-size: 12px;line-height: 18px}

/* News Release Page */
.news-section #page-content table.uk-table th {background: #333; color: #fff}
i.uk-icon-file-o,
i.uk-icon-file-movie-o,
i.uk-icon-file-text-o,
i.uk-icon-file-pdf-o {color: $color-primary}
i.uk-icon-file-o:hover,
i.uk-icon-file-movie-o:hover,
i.uk-icon-file-text-o:hover,
i.uk-icon-file-pdf-o:hover {color: $default-fontcolor}

/* Presentation Page */
.policies-list .list-item i {padding-right: 30px;}

/* Events Page */
.events-list h2 {font-size: 20px}
.events-list .uk-panel {padding: 25px; font-size: 14px; line-height: 18px; border-top: 1px solid #d7d1c6}

/* Media Page */
.extras {font-size: 14px; line-height: 18px;}
.media-link {top: 25px; right: 0}

.videos-page iframe {width: 70vw; height: 40.25vw;}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Misc Crap ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Parsley overrides */
input.parsley-success,select.parsley-success,textarea.parsley-success {color: #468847;background-color: #DFF0D8;border: 1px solid #D6E9C6}
input.parsley-error,select.parsley-error,textarea.parsley-error{color:#B94A48;background-color:#F2DEDE;border: 1px solid #EED3D7}
.parsley-errors-list {margin: 2px 0 3px;padding: 0; color: #c00;list-style-type: none;font-size: 0.9em;line-height: 0.9em;opacity: 0;transition: all .3s ease-in;-o-transition: all .3s ease-in;-moz-transition: all .3s ease-in;-webkit-transition: all .3s ease-in}
.parsley-errors-list.filled {opacity:1}
.footer-features .parsley-errors-list {position: absolute; left:0;bottom:-25px}
#contact .parsley-errors-list {margin-top: 5px}

/* Join button in footer */
.join-list > .uk-button-primary {font-size: 15px; font-weight: 400; line-height: 40px; background: $color-primary !important; transition: background 2s; border: none; border-radius: 0}
.join-list > .uk-button-primary:hover {background: rgba($color-primary,0.6) !important}
.join-list > .uk-button-primary span {padding: 0 5px 0 15px; line-height: 40px;}

/* Map */
.map {width: 100%; height: 350px}
.map img {max-width: none}
svg { max-height: 100%}

/* uikit overrides */
em {color: inherit}

/* Photo Gallery */
.gallery-image {margin-bottom: 10px; overflow: hidden}

/* Tables */

/* Pop up form */

/* Misc */
.iframe-container {position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;}
.iframe-container iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

ul.uk-list-striped li.year-header {background: none; border-bottom: 1px solid $color-primary}
.uk-table-striped table tbody tr:nth-of-type(odd) {background: none}

/* Media queries */
@media (min-width: 0px) and (max-width: $mini - 1) {
#slider {height: 80px}
.home .slide {width: 100%; height: 240px} 
.home #slider {height: 240px}
    .feature {min-height: 160px;}
}

@media (min-width: $mini) and (max-width: $small - 1) {
#slider {height: $sub-slider-height-tiny}
.home .slide {width: 100%; height: $home-slider-height-small}
.home #slider {height: $home-slider-height-small}
.tagline {max-width: 350px; margin-top: 10px; padding: 10px;}
.tagline h2 {font-size: 24px; line-height: 28px;}
    .feature {min-height: 160px;}
}

@media (min-width: $small) and (max-width: $medium - 1) {
#slider {height: $sub-slider-height-small}
.home .slide {width: 100%; height: $home-slider-height-small} 
.home #slider {height: $home-slider-height-small}
.tagline {max-width: 350px; margin-top: 20px; padding: 10px;}
.tagline h2 {font-size: 24px; line-height: 28px;}
    .feature {min-height: 160px;}
}

@media (min-width: $medium) and (max-width: $large - 1) {
    .main-nav {padding-top: 36px;}
    .main-nav > li > a {padding: 0 8px; font-size: $nav-fontsize-768}
#slider {height: $sub-slider-height-medium}
.home .slide {width: 100%; height: $home-slider-height-medium}
.home #slider {height: $home-slider-height-medium}
.tagline {max-width: 350px; margin-top: 10%; padding: 10px;}
.tagline h2 {font-size: 36px; line-height: 40px;}
.home-text,
.latest-news {padding: 25px 25px 55px 25px}
}

@media (min-width: $large) and (max-width: $xlarge - 1) {
    .main-nav {padding-top: 36px;}
    .main-nav > li > a {font-size: $nav-fontsize-980}
#slider {height: $sub-slider-height}
.home .slide {width: 100%; height: $home-slider-height}
.home #slider {height: $home-slider-height}
.home-text,
.latest-news {padding: 25px 25px 55px 25px}
}

@media (min-width: $xlarge) {
    .main-nav {padding-top: 46px;}
#slider {height: $sub-slider-height}
.home .slide {width: 100%; height: $home-slider-height}
.home #slider {height: $home-slider-height}
    .home-text {padding: 0 250px;}
    .home-message div div div {padding: 0 100px;}
}
